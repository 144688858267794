import React from 'react';
import SidebarButton from './SidebarButton';
import { ReactComponent as Home } from '../images/home.svg';
import { ReactComponent as DataCollection } from '../images/data-collection.svg';
import { ReactComponent as AINetwork } from '../images/ai-network.svg';
import { ReactComponent as Climate } from '../images/climate.svg';
import { ReactComponent as Alert } from '../images/alert.svg';
import { ReactComponent as Satellite } from '../images/satellite.svg';
import { ReactComponent as Simulator } from '../images/simulator.svg';
import { ReactComponent as Dashboard } from '../images/dashboard.svg';
import { ReactComponent as Reporting } from '../images/reporting.svg';
import { ReactComponent as Integration } from '../images/integration.svg';

function Sidebar() {
  return (
    <div className="sidebar">
      <SidebarButton title="Home" icon={<Home />} />
      <SidebarButton title="Data Source" icon={<DataCollection />} />
      <SidebarButton title="AI Model" icon={<AINetwork />} />
      <SidebarButton title="Climate Engine" icon={<Climate />} />
      <SidebarButton title="Alarms Engine" icon={<Alert />} />
      <SidebarButton title="Assestmnts &amp; Monitoring" icon={<Satellite />} />
      <SidebarButton title="Simultaor" icon={<Simulator />} />
      <SidebarButton title="Dashboard" icon={<Dashboard />} />
      <SidebarButton title="Reporting" icon={<Reporting />} />
      <SidebarButton title="Third Party Integration" icon={<Integration />} />
    </div>
  );
}

export default Sidebar;