import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

function SidebarButton({ title, icon }) {
  const location = useLocation();
  const { pathname } = location;
  const active = title === 'Home';
  const disabled = !pathname.startsWith('/activity/');
  return (
    <div className={classNames("sidebar-button", active && 'active', !active && disabled && 'disabled')}>
      {icon}
      <span>{title}</span>
    </div>
  );
}

export default SidebarButton;