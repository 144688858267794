import React from "react";
import "./scss/styles.scss";
import "./icons/icons.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Activity from './pages/Activity';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/activity/:activityId" element={<Activity />} />
      </Routes>
    </Router>
  );
}

export default App;