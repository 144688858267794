import React from 'react';

function Dropdown({ title, inverted }) {
  return (
    <div className="dropdown">
      {inverted && <i className="icon-chevron-down"></i>}
      <div>{title}</div>
      {!inverted && <i className="icon-chevron-down"></i>}
    </div>
  );
}

export default Dropdown;