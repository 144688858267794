import React from 'react';
import Dropdown from './Dropdown';
import Logo from "../images/logo.svg";

function Header() {
  return (
    <header className="header">
      <img className="logo" src={Logo} alt="Titan4 Logo" />
      <div style={{ display: 'flex' }}>
        <Dropdown title="Notificatons" inverted={true} />
        <Dropdown title="Settings" inverted={true} />
      </div>
    </header>
  );
}

export default Header;