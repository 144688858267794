import React from 'react';

function DemoItem({ demo }) {
  return (
    <div className="demo-item">
      <img className="thumbnail" src={demo.thumbnail} alt={demo.name} />
      <div>
        <h5 style={{ margin: 0 }}>{demo.name}</h5>
        <span>{demo.description}</span>
      </div>
    </div>
  );
}

export default DemoItem;