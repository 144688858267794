import React from 'react';

function MenuItem({ name }) {
  return (
    <div className="menu-item">
      {name}
    </div>
  );
}

export default MenuItem;