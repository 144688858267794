import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ContentWrapper from '../components/ContentWrapper';
import TeamItem from '../components/TeamItem';
import Overview from '../images/overview1.png';
import { client } from "../services/api";

function Activity() {
  const navigate = useNavigate();
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    client.getRequests()
      .then((data) => setRequests(data))
      .catch((err) => console.error(err.message));
  }, []);

  return (
    <div className="activity">
      <Header />
      <div className="main-wrapper">
        <Sidebar />
        <div className='main-content'>
          <div className="main-content-primary">
            <div style={{ marginLeft: 32, cursor: 'pointer' }} onClick={() => navigate('/')}>
              <i className='icon-chevron-left'></i><span>Back</span>
            </div>
            <ContentWrapper>
              <h3>Activity Name 1</h3>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <img src={Overview} alt="overview" />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                  <div><b>Detail 1</b><span>XXXXXXXXX</span></div>
                  <div><b>Detail 2</b><span>XXXXXXXXX</span></div>
                  <div><b>Detail 3</b><span>XXXXXXXXX</span></div>
                  <div><b>Detail 4</b><span>XXXXXXXXX</span></div>
                </div>
              </div>
              <h5>Description</h5>
              <p>Lorem ipsum dolor sit amet consectetur. Ut neque sed sit neque placerat nascetur ultricies pretium. Viverra ut risus velit donec scelerisque diam augue nullam. Sed diam gravida consequat ante porta pharetra diam massa. Quis tincidunt nascetur mi mattis dictum et nec tellus dolor. Amet gravida non diam morbi sed phasellus. Dignissim nam odio eget a neque elit leo.</p>
            </ContentWrapper>
            <ContentWrapper>
              <h5>Requests</h5>
              {requests.map((request) => (
                <div key={request.id} style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: '#fff',
                  padding: 20,
                  borderRadius: 10,
                  marginBottom: 6
                }}>
                  <span>{request.name}</span>
                  <span>{request.createDate}</span>
                  <span>{request.status}</span>
                  <span><a href="#">Request details</a></span>
                </div>
              ))}
            </ContentWrapper>
          </div>
          <div className="main-content-sidebar" style={{ marginTop: 20 }}>
            <ContentWrapper>
              <h4>Your team</h4>
              <TeamItem />
              <TeamItem />
              <TeamItem />
              <span>+ Add member</span>
            </ContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Activity;