import React from 'react';
import Dropdown from './Dropdown';
import Search from '../images/search.svg';

function SearchBar() {
  return (
    <form className="search-bar">
        <div className="search">
          <img src={Search} alt="Search" />
          <input type="text" id="search" name="search" placeholder='Search' />
        </div>
        
        <Dropdown title="Private Projects" inverted={false} />
        <span><i className="icon-filter"></i> Filter</span>
        <span><i className="icon-sort"></i> Sort by</span>
    </form>
  );
}

export default SearchBar;