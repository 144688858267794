import axios from 'axios';

import ActivityThumb1 from '../images/thumbnails/activity-thumb1.png'
import ActivityThumb2 from '../images/thumbnails/activity-thumb2.png'
import ActivityThumb3 from '../images/thumbnails/activity-thumb3.png'
import DemoThumb1 from '../images/thumbnails/demo-thumb1.png'
import DemoThumb2 from '../images/thumbnails/demo-thumb2.png'
import DemoThumb3 from '../images/thumbnails/demo-thumb3.png'
import DemoThumb4 from '../images/thumbnails/demo-thumb3.png'

export const create = (baseURL = process.env.REACT_APP_URL_API) => {
  const api = axios.create({
    baseURL,
    headers: {
    },
    timeout: 60000,
  });

  const getProjects = () => {
    //return api.get(`/api/projcts`);
    return Promise.resolve([{
      'id': 1,
      'name': 'Activity Name 1',
      'lastOpened': '17 hours',
      'private': true,
      'thumbnail': ActivityThumb1,
    },
    {
      'id': 2,
      'name': 'Activity Name 2',
      'lastOpened': '2 days',
      'private': true,
      'thumbnail': ActivityThumb2,
    },
    {
      'id': 3,
      'name': 'Activity Name 3',
      'lastOpened': '6 days',
      'private': false,
      'thumbnail': ActivityThumb3,
    }])
  };

  const getDemos = () => {
    //return api.get(`/api/demos`);
    return Promise.resolve([
      {
        'id': 1,
        'name': 'Demo 1',
        'description': 'Lorem ipsum dolor sit amet consectetur. Fermentum sed.',
        'thumbnail': DemoThumb1,
      },
      {
        'id': 2,
        'name': 'Demo 2',
        'description': 'Lorem ipsum dolor sit amet consectetur. Fermentum sed.',
        'thumbnail': DemoThumb2,
      },
      {
        'id': 3,
        'name': 'Demo 3',
        'description': 'Lorem ipsum dolor sit amet consectetur. Fermentum sed.',
        'thumbnail': DemoThumb3,
      },
      {
        'id': 4,
        'name': 'Demo 4',
        'description': 'Lorem ipsum dolor sit amet consectetur. Fermentum sed.',
        'thumbnail': DemoThumb4,
      }
    ])
  };

  const getRequests = () => {
    //return api.get(`/api/requests`);
    return Promise.resolve([
      {
        'id': 1,
        'name': 'Request 01',
        'createDate': '02/12/2023',
        'status': 'Done'
      },
      {
        'id': 2,
        'name': 'Request 02',
        'createDate': '12/01/2024',
        'status': 'Done'
      },
      {
        'id': 3,
        'name': 'Request 03',
        'createDate': '19/02/2024',
        'status': 'Under Review'
      },
      {
        'id': 4,
        'name': 'Request 04',
        'createDate': '26/05/2024',
        'status': 'Under Review'
      }
    ])
  };

  return {
    getProjects,
    getDemos,
    getRequests,
    api,
  };
}

export const client = create();
