import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import SearchBar from './SearchBar';
import { client } from "../services/api";
import ActivityItem from './ActivityItem';

function Activities() {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    client.getProjects()
      .then((data) => setProjects(data))
      .catch((err) => console.error(err.message));
  }, []);

  return (
    <div className="activities">
      <h5>Activities</h5>
      <SearchBar />
      <div className='section-bubble'>
        <span>+ Create a New activity</span>
      </div>
      {projects.map((project) => (
        <div key={project.id} className='section-bubble' style={{ cursor: 'pointer' }} onClick={() => navigate(`/activity/${project.id}`)}>
          <ActivityItem project={project} />
        </div>
      ))}
    </div>
  );
}

export default Activities;