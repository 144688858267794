import React from 'react';
import MenuItem from '../components/MenuItem';
import User from '../images/user.png';

function AccountMenu() {
  return (
    <div className="account-menu">
      <div style={{ textAlign: 'center', paddingBottom: 20 }}>
      <img src={User} alt="User" />
        <span>username.surname@company.com</span>
      </div>
      <MenuItem name="Profile" />
      <MenuItem name="Requests" />
      <MenuItem name="Privacy Policy" />
      <span className="sign-out">
        <i className='icon-sign-out'></i>
        <div>Sign Out</div>
      </span>
    </div>
  );
}

export default AccountMenu;