import React, { useState, useEffect } from 'react';
import { client } from "../services/api";
import DemoItem from './DemoItem';

function Demos() {
  const [demos, setDemos] = useState([]);

  useEffect(() => {
    client.getDemos()
      .then((data) => setDemos(data))
      .catch((err) => console.error(err.message));
  }, []);

  return (
    <div className="demos">
      <h5>Demos</h5>
      <span>See how Titan4 works across different industries.</span>
      <div>
      {demos.map((demo) => (
        <DemoItem key={demo.id} demo={demo} />
      ))}
      </div>
    </div>
  );
}

export default Demos;