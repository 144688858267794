import React from 'react';
import User from '../images/user.png';

function TeamItem() {
  return (
    <div className="team-item" style={{ display: 'flex', margin: '20px 0' }}>
      <img src={User} alt="User" />
      <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
        <span>name.surname@gmail.com</span>
        <span>Can View <i className='icon-chevron-down'></i></span>
      </div>
    </div>
  );
}

export default TeamItem;