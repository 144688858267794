import React from 'react';
import Status from '../images/status.svg'
import classnames from 'classnames';

function ActivityItem({ project }) {
  return (
    <div className="activity-item" style={{ display: 'flex' }}>
      <img className="thumbnail" src={project.thumbnail} alt={project.name} />
      <div style={{ paddingTop: 12, marginLeft: 16 }}>
        <h5 style={{ margin: 0 }}>{project.name}</h5>
        <span><img src={Status} alt="Status" /> Opened {project.lastOpened} ago</span>
      </div>
      <div className={classnames('ribbon', project.private ? 'private' : 'public')}>{project.private ? 'Private' : 'Public'}</div>
    </div>
  );
}

export default ActivityItem;