import React from 'react';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import ContentWrapper from '../components/ContentWrapper';
import Activities from '../components/Activities';
import Demos from '../components/Demos';
import AccountMenu from '../components/AccountMenu';
import MenuItem from '../components/MenuItem';

function Home() {
    return (
        <div className="home">
            <Header />
            <div className="main-wrapper">
                <Sidebar />
                <div className='main-content'>
                    <div className="main-content-primary">
                        <ContentWrapper>
                            <Activities />
                        </ContentWrapper>
                        <Demos />
                    </div>
                    <div className="main-content-sidebar">
                        <ContentWrapper>
                            <AccountMenu />
                        </ContentWrapper>
                        <ContentWrapper>
                            <MenuItem name="Contact Us" />
                            <MenuItem name="Support" />
                            <MenuItem name="FAQ" />
                        </ContentWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;